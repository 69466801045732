const NotificationBanner = (props) => {
  return (
    <>
      <div id='notification-banner-main'>
        {props.notifications.map((item, idx1) => {
          return (
            <div key={idx1} className={`notification-message-${item.type}`}>
              {item.message}
            </div>
          )
        })}
      </div>
    </>
  )
}

export default NotificationBanner;