import { useEffect, useState } from 'react';
import AppFrame from './containers/AppFrame/AppFrame';
import Header from './containers/Header/Header';
import Navigation from './components/Navigation/Navigation';
import Footer from './containers/Footer/Footer';
import NotificationBanner from './containers/NotificationBanner/NotificationBanner';
import toggles from './config/toggles';
import UnderConstruction from './components/UnderConstruction/UnderConstruction';
import { records as recordsMock } from './testData/mlbStandings';
import { stats as hittingStatsMock } from './testData/mlbHittingStats';
import { stats as pitchingStatsMock } from './testData/mlbPitchingStats';

import './App.css';
import { getStandings } from './apis/getStandings';
import { getHittingStats } from './apis/getHittingStats';
import { getPitchingStats } from './apis/getPitchingStats';

function App() {
  const [standingsYear, setStandingsYear] = useState('2024');
  const [statsYear, setStatsYear] = useState('2024');
  const [mlbStandingsData, setMLBStandingsData] = useState([]);
  const [mlbHittingStatsData, setMLBHittingStatsData] = useState([]);
  const [mlbPitchingStatsData, setMLBPitchingStatsData] = useState([]);

  // Mount
  useEffect(() => {
    if (toggles.useLiveData) {
      getStandings(standingsYear).then((data) => {
        // console.log(data)
        setMLBStandingsData(data);
      });
      getHittingStats(statsYear).then((data) => {
        // console.log(data)
        setMLBHittingStatsData(data);
      });
      getPitchingStats(statsYear).then((data) => {
        // console.log(data)
        setMLBPitchingStatsData(data);
      });
    } else {
      setMLBStandingsData(recordsMock);
      setMLBHittingStatsData(hittingStatsMock);
      setMLBPitchingStatsData(pitchingStatsMock);
    }
  }, [standingsYear, statsYear])

  // console.log("Play Ball!")

  return (
    <div className="App">
      <header className="App-header">
        <Header />
      </header>
      {!toggles.useLiveData ? <NotificationBanner notifications={[{ message: '***** Heads up, we have live data turned off right now for testing! *****', type: 'warning' }]} /> : null}
      {!toggles.updateMessage ? <NotificationBanner notifications={[{ message: 'SABRScore is now available!', type: 'success' }]} /> : null}
      {toggles.constructionBuild ? <UnderConstruction /> :
        <>
          <Navigation />
          <br />
          <AppFrame mlbStandingsData={mlbStandingsData} hittingStats={mlbHittingStatsData} pitchingStats={mlbPitchingStatsData} setYearStatsFn={setStatsYear} setYearStandingsFn={setStandingsYear} standingsYearSelected={standingsYear} statsYearSelected={statsYear} />
        </>
      }
      <Footer />
    </div>
  );
}

export default App;
