import "./slider.css";

const ToggleSlider = (props) => {
  return (
    <>
      <label className="switch">
        <input type="checkbox" checked={props.checked} onChange={props.handleChange} />
        <span className="slider round"></span>
      </label>
      <span style={{ paddingLeft: '15px' }}>{props.title}</span>
    </>
  )
}

export default ToggleSlider;