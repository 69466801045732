import React from 'react';
import './Scoreboard.css';

const Tile = (props) => {
  let classes = 'tile';

  if (props.type === 'left-bold') {
    classes += ' left-bold';
  } else if (props.type === '1') {
    classes = 'tile-wide';
  }
  if (props.win === '1') {
    classes += ' win-hi'
  }

  return (
    <div className={classes}>
      {props.value}
    </div>
  );

}

const Scoreboard = (props) => {

  let aWin = props.results[props.id].aTeamScore;
  let hWin = props.results[props.id].bTeamScore;

  let aWinfl = '0';
  let hWinfl = '0';

  if (aWin > hWin) {
    aWinfl = '1';
  } else {
    hWinfl = '1';
  }

  // console.log(aWinfl)

  const top = [];
  top.push(<Tile type='1' />);
  for (let i = 1; i < 10; i++) {
    top.push(<Tile value={i} />);
  }
  top.push(<Tile value='R' type='left-bold' />);
  top.push(<Tile value='H' />);
  top.push(<Tile value='E' />);

  const away = [];
  away.push(<Tile value={props.data.teamA.name} type='1' win={aWinfl} />);
  for (let i = 0; i < 9; i++) {
    away.push(<Tile value={props.results[props.id].aTeamRuns[i]} />);
  }
  away.push(<Tile value={props.results[props.id].aTeamScore} type='left-bold' win={aWinfl} />);
  away.push(<Tile value={props.results[props.id].aTeamHits} win={0} />);
  away.push(<Tile value={0} win={0} />);

  const home = [];
  home.push(<Tile value={props.data.teamH.name} type='1' win={hWinfl} />);
  for (let i = 0; i < 9; i++) {
    home.push(<Tile value={props.results[props.id].bTeamRuns[i]} />);
  }
  home.push(<Tile value={props.results[props.id].bTeamScore} type='left-bold' win={hWinfl} />);
  home.push(<Tile value={props.results[props.id].bTeamHits} win={0} />);
  home.push(<Tile value={0} win={0} />);

  return (
    <div className='scoreboard'>
      {top}
      {away}
      {home}
    </div>
  );
}

export default Scoreboard;