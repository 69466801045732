import { useState } from 'react';
import {
  Link
} from "react-router-dom";

const Navigation = () => {

  const [selectedPage, setSelectedPage] = useState('/')

  return (
    <>
      <nav>
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
          <Link className={`nav-link ${selectedPage === '/' ? 'active' : ''}`} id="nav-home-tab" role="tab" aria-controls="nav-home" aria-selected={selectedPage === '/'} onClick={() => { setSelectedPage('/') }} to='/'>Home</Link>
          {/* <Link className={`nav-link ${selectedPage === '/info' ? 'active' : ''}`} id="nav-info-tab" role="tab" aria-controls="nav-info" aria-selected={selectedPage === '/info'} onClick={() => { setSelectedPage('/info') }} to='/info'>Info</Link> */}
          <Link className={`nav-link ${selectedPage === '/sabrscore' ? 'active' : ''}`} id="nav-powerrankings-tab" role="tab" aria-controls="nav-powerrankings" aria-selected={selectedPage === '/sabrscore'} onClick={() => { setSelectedPage('/sabrscore') }} to='/sabrscore'>SABRScore</Link>
          <Link className={`nav-link ${selectedPage === '/standings' ? 'active' : ''}`} id="nav-standings-tab" role="tab" aria-controls="nav-standings" aria-selected={selectedPage === '/standings'} onClick={() => { setSelectedPage('/standings') }} to='/standings'>Standings</Link>
          <Link className={`nav-link ${selectedPage === '/simulations' ? 'active' : ''}`} id="nav-simulations-tab" role="tab" aria-controls="nav-simulations" aria-selected={selectedPage === '/simulations'} onClick={() => { setSelectedPage('/simulations') }} to='/simulations'>Play Ball</Link>
          {/* <Link className={`nav-link ${selectedPage === '/patchnotes' ? 'active' : ''}`} id="nav-patchnotes-tab" role="tab" aria-controls="nav-patchnotes" aria-selected={selectedPage === '/patchnotes'} onClick={() => { setSelectedPage('/patchnotes') }} to='/patchnotes'>Patch Notes</Link> */}
        </div>
      </nav>
    </>
  )
}

export default Navigation;