import axios from 'axios';

export async function getPitchingStats(year) {
  const url = `https://bdfed.stitch.mlbinfra.com/bdfed/stats/team?stitch_env=prod&sportId=1&gameType=R&group=pitching&order=asc&sortStat=earnedRunAverage&stats=season&season=${year}&limit=30&offset=0`
  const config = {
    url: url,
    method: 'get',
    headers: {
      "accept": "*/*",
      "accept-language": "en-US,en;q=0.9"
    }
  }

  try {
    console.log('Attempting to connect to stat api for pitching')
    const response = await axios(config);
    return response.data.stats;
  } catch (error) {
    return error;
  }

}