import {
  Route, Switch
} from "react-router-dom";
import StandingsPage from "../StandingsPage/StandingsPage";
// import PatchNotes from "../PatchNotes/PatchNotes";
// import Information from "../Information/Information";
import Homepage from "../Homepage/Homepage";
import Simulations from "../../components/Simulations/Simulations";
import RankingsPage from "../RankingsPage/RankingsPage";

const AppFrame = (props) => {

  return (
    <>
      <div className="container">
        <Switch>
          {/* <Route path="/info">
            <Information />
          </Route> */}
          <Route path="/users">
            <h1>Coming Soon.</h1>
          </Route>
          <Route path='/standings'>
            <StandingsPage mlbStandingsData={props.mlbStandingsData} setYearStandingsFn={props.setYearStandingsFn} standingsYearSelected={props.standingsYearSelected} />
          </Route>
          <Route path='/sabrscore'>
            <RankingsPage hittingStats={props.hittingStats} pitchingStats={props.pitchingStats} setYearStatsFn={props.setYearStatsFn} statsYearSelected={props.statsYearSelected} />
          </Route>
          <Route path='/simulations'>
            <Simulations />
          </Route>
          <Route exact path="/">
            <Homepage />
            {/* <StandingsPage mlbStandingsData={props.mlbStandingsData} /> */}

          </Route>
          {/* <Route path='/patchnotes'>
            <PatchNotes />
          </Route> */}
        </Switch>
        <div className="row">
          {/* <div className="col-sm">
            One of three columns
    </div>
          <div className="col-sm">
            One of three columns
    </div>
          <div className="col-sm">
            One of three columns
            <button onClick={() => generateTable()}>
              Generate Table
            </button> */}

          {/* </div> */}
        </div>


      </div>
    </>
  )
}

export default AppFrame;