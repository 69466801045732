import axios from 'axios';

export async function getStandings(year) {
  const url = `https://statsapi.mlb.com/api/v1/standings?leagueId=103,104&season=${year}&standingsTypes=regularSeason,firstHalf,secondHalf&hydrate=division,conference,sport,league,team(nextSchedule(team,gameType=[R,F,D,L,W,C],inclusive=false),previousSchedule(team,gameType=[R,F,D,L,W,C],inclusive=true))`;
  const config = {
    url: url,
    method: 'get',
    headers: {
      "accept": "*/*",
      "accept-language": "en-US,en;q=0.9",
    }
  }

  try {
    console.log('Attempting to connect to standing api for pitching')
    const response = await axios(config);
    return response.data.records;
  } catch (error) {
    return error;
  }

}