// import { calculateTeamHitting } from '../../algorithmn';
// import { stats } from '../../testData/mlbHittingStats';
import './Header.css';

const Header = (props) => {

  return (
    <>
      <div id='sb-header' className='bg-darkblue height-banner flex'>
        <h3 id='sb-header-title' className='font-white bg-darkblue'>SimulateBaseball</h3>
      </div>
    </>
  )
}

export default Header;