// import { records } from '../../testData/mlbStandings';

import ToggleSlider from "../../components/ToggleSlider/ToggleSlider";
import { useEffect, useState } from 'react';

function generateTable(data, settings) {
  const HPT = settings.highlightPlayoffTeams;

  let output = [];
  data.forEach((division) => {
    output.push(<tr><th>{division.division.name}</th><th></th><th></th><th></th><th></th></tr >)
    output.push(
      division.teamRecords.map((teamObj, idx2) => {
        let playoffSeed = teamObj.clinched && teamObj.clinchIndicator ? teamObj.clinchIndicator : null;
        return (<tr key={idx2} className={`${idx2 % 2 === 0 ? 'bg-gray' : ''} ${HPT && (teamObj.divisionLeader || teamObj.wildCardRank <= 3) ? 'highlightRow' : ''}`}><td></td><td>{teamObj.team.name}{playoffSeed && ` (${playoffSeed})`}</td><td>{teamObj.wins}</td><td>{teamObj.losses}</td><td>{teamObj.winningPercentage}</td></tr>)
      }))
  })
  return output;

}

const StandingsPage = (props) => {
  const [highlightPlayoffTeams, setHighlightPlayoffTeams] = useState(false);
  const [settings, setSettings] = useState({});

  useEffect(() => {
    setSettings({ highlightPlayoffTeams });
  }, [highlightPlayoffTeams])

  return (
    <>
      <div>
        <h3>{props?.mlbStandingsData[0]?.division.season} Regular Season</h3>
        <br />
        <div>
          <h5>Settings</h5>
          <ToggleSlider checked={highlightPlayoffTeams} handleChange={() => setHighlightPlayoffTeams(!highlightPlayoffTeams)} title="Highlight Playoff Teams" />
          <br />
          <br />
          Year basis:&nbsp;
          <select onChange={(sel) => props.setYearStandingsFn(sel.target.value)} value={props.standingsYearSelected}>
            <option value='2024'>2024</option>
            <option value='2023'>2023</option>
            <option value='2022'>2022</option>
            <option value='2021'>2021</option>
            <option value='2020'>2020*</option>
            <option value='2019'>2019</option>
            <option value='2018'>2018</option>
            <option value='2017'>2017</option>
            <option value='2016'>2016</option>
            <option value='2015'>2015</option>
            <option value='2014'>2014</option>
          </select>
          <br />
          <br />
          <div>
            z = clinched best record in league &nbsp;&nbsp;|&nbsp;&nbsp;
            y = clinched division &nbsp;&nbsp;|&nbsp;&nbsp;
            w = clinched wild card
          </div>
        </div>
        <table id='standingsTable' className="table bg-white">
          <thead><tr><th></th><th>Team</th><th>Wins</th><th>Losses</th><th>%</th></tr></thead>
          <tbody className='bg-white'>
            {generateTable(props.mlbStandingsData, settings)}
          </tbody>
        </table>
        <br />
        <br />
        <br />
      </div>
    </>
  )
}

export default StandingsPage