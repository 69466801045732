let data = {
  teamA: {
    name: 'LAD',
    hits: 0,
    runs: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    score: 0,
    wins: 0,
    loss: 0,
  },
  teamH: {
    name: 'BOS',
    hits: 0,
    runs: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    score: 0,
    wins: 0,
    loss: 0
  },
  inning: 0,
  tied: false,
  balls: 0,
  strikes: 0,
  outs: 0,
  bases: [0, 0, 0],
  eigame: 0,
  gameLog: [],
  result: [],
}

// //range will be from 0-100
// const hitRates = {
//   raw: {
//     hr: 0.15,
//     tr: 0.03,
//     db: 0.20,
//     sg: 0.62
//   },
//   range: {
//     hr: 15, //1-15
//     tr: 18, //16-18
//     db: 38, //19-38
//     sg: 100 //39-100
//   }
// }

function randInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

function updateScore(team, score) {
  console.log("ADDED SCORE ===> " + score)
  if (team === 'A') {
    data.teamA.score += score;
    data.teamA.runs[data.inning] += score;
    //console.log(data.teamA.runs);
  } else {
    data.teamH.score += score;
    data.teamH.runs[data.inning] += score;

  }
  //displayScoreboard();
}

function moveBases(totalBases, team) {
  // check 3rd, 2nd, 1st move each the number of bases respectively
  let newScore = 0;

  if (totalBases > 4) {
    data.bases = [0, 0, 0]; // 3 outs clears bases
  }
  else if (totalBases === 4) { //HOMERUN CONDITIONS
    if (data.bases[2] === 1) {
      newScore++; //adds run
      data.bases[2] = 0; //clears 3rd base
    }
    if (data.bases[1] === 1) {
      newScore++; //adds run
      data.bases[1] = 0; //clears 2nd base
    }
    if (data.bases[0] === 1) {
      newScore++; //adds run
      data.bases[0] = 0; //clears 1st base
    }
    newScore++;
  }
  else if (totalBases === 3) { //TRIPLE CONDITION
    if (data.bases[2] === 1) {
      newScore++; //adds run
      data.bases[2] = 0; // clears 3rd base
    }
    if (data.bases[1] === 1) {
      newScore++; //adds run
      data.bases[1] = 0; //clears 2nd base
    }
    if (data.bases[0] === 1) {
      newScore++; //adds run
      data.bases[0] = 0; //clears 1st base
    }
    data.bases[2] = 1; //runer tripled
  }
  else if (totalBases === 2) { //DOUBLE CONDITION
    if (data.bases[2] === 1) {
      newScore++; //adds run
      data.bases[2] = 0; // 3rd base cleared
    }
    if (data.bases[1] === 1) {
      newScore++; //adds run
      data.bases[1] = 0; //clears 2nd base
    }
    if (data.bases[0] === 1) {
      data.bases[2] = 1; //adds runner to 3rd base
      data.bases[0] = 0; //clears 1st base
    }
    data.bases[1] = 1; //runner doubled
  }
  else if (totalBases === 1) { //SINGLE CONDITION
    if (data.bases[2] === 1) {
      newScore++; //adds run
      data.bases[2] = 0; // 3rd base cleared
    }
    if (data.bases[1] === 1) {
      data.bases[2] = 1; //adds runner to third base
      data.bases[1] = 0; //clears 2nd base
    }
    if (data.bases[0] === 1) {
      data.bases[1] = 1; //adds runner to 2nd base
      data.bases[0] = 0; //clears 1st base
    }
    data.bases[0] = 1; //runner singled no matter what
  }

  console.log('  [' + data.bases[1] + ']  ');
  console.log('[' + data.bases[2] + '] [' + data.bases[0] + ']');
  if (newScore !== 0) {
    data.gameLog.push(newScore + ' RUNS SCORED')
    updateScore(team, newScore);
  }
  if (data.teamA.score === data.teamH.score) {
    data.tied = true;
  } else {
    data.tied = false;
  }
}

function batter(team, ba, hr, tr, db, sg) {
  let hit;
  if (randInt(1000) <= ba) {
    hit = true;
  } else {
    hit = false;
    data.outs++;
    data.gameLog.push("OUT");
  }
  if (hit === true) {
    if (team === 'A') {
      data.teamA.hits += 1;
    } else {
      data.teamH.hits += 1;
    }
    let whichType = randInt(1000);
    if (whichType <= hr) {
      console.log("HOME RUN! HOME RUN!");
      data.gameLog.push("HR");
      moveBases(4, team);
    } else if (whichType <= tr && whichType > hr) {
      console.log("TRIPLE");
      data.gameLog.push("3B");
      moveBases(3, team);
    } else if (whichType <= db && whichType > tr) {
      console.log("DOUBLE");
      data.gameLog.push("2B");
      moveBases(2, team);
    } else if (whichType <= sg && whichType > db) {
      console.log("SINGLE");
      data.gameLog.push("1B");
      moveBases(1, team);
    }
  }
}

function inning() {
  while (data.outs < 3) {
    batter('A', data.teamA.rates.BA, data.teamA.rates.HRR, data.teamA.rates.TRR, data.teamA.rates.DBR, data.teamA.rates.SNR);
  }
  console.log(`END Top ${(data.inning + 1)}`)
  data.gameLog.push("END T" + (data.inning + 1))
  data.outs = 0;
  data.bases = [0, 0, 0];
  //displayScoreboard();
  while (data.outs < 3) {
    batter('H', data.teamH.rates.BA, data.teamH.rates.HRR, data.teamH.rates.TRR, data.teamH.rates.DBR, data.teamH.rates.SNR);
  }
  console.log(`END Bottom ${(data.inning + 1)}`)
  data.gameLog.push("END B" + (data.inning + 1))
  data.outs = 0;
  data.bases = [0, 0, 0];


  //displayScoreboard();
  data.inning++;
}

function displayScoreboard() {
  console.log('++++++++++++++++++++++++++++++');
  console.log('|++++|1|2|3|4|5|6|7|8|9|H|R|E|')
  console.log('|' + data.teamA.name + '|' + data.teamA.runs[0] + '|' + data.teamA.runs[1] + '|' + data.teamA.runs[2] + '|' + data.teamA.runs[3] + '|' + data.teamA.runs[4] + '|' + data.teamA.runs[5] + '|' + data.teamA.runs[6] + '|' + data.teamA.runs[7] + '|' + data.teamA.runs[8] + '|' + data.teamA.hits + '|' + data.teamA.score + '|0|')
  console.log('|' + data.teamH.name + '|' + data.teamH.runs[0] + '|' + data.teamH.runs[1] + '|' + data.teamH.runs[2] + '|' + data.teamH.runs[3] + '|' + data.teamH.runs[4] + '|' + data.teamH.runs[5] + '|' + data.teamH.runs[6] + '|' + data.teamH.runs[7] + '|' + data.teamH.runs[8] + '|' + data.teamH.hits + '|' + data.teamH.score + '|0|')
  console.log('++++++++++++++++++++++++++++++');

}

function packageResults() {
  let gameResult = {
    inning: data.inning,
    aTeamRuns: data.teamA.runs,
    aTeamScore: data.teamA.score,
    aTeamHits: data.teamA.hits,
    bTeamRuns: data.teamH.runs,
    bTeamScore: data.teamH.score,
    bTeamHits: data.teamH.hits,
    gameLog: data.gameLog,
  }
  data.result.push(gameResult);
}

function simulateGame() {
  while ((data.inning < 9) || (data.tied === true)) {
    console.log('INNING #' + (data.inning + 1));
    inning();
  }
  if (data.inning > 9) {
    data.eigame++;
  }
  if (data.teamA.score > data.teamH.score) {
    data.teamA.wins++;
    data.teamH.loss++;
  } else if (data.teamH.score > data.teamA.score) {
    data.teamA.loss++;
    data.teamH.wins++;
  }

  console.log('FINAL SCORE:');
  displayScoreboard();
  packageResults();
  data.inning = 0;
  data.teamA.runs = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  data.teamA.score = 0;
  data.teamA.hits = 0;
  data.teamH.runs = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  data.teamH.score = 0;
  data.teamH.hits = 0;
  data.gameLog = [];
}

function resetData(teamA, teamH, teamAStats, teamHStats) {
  data.result = [];
  data.teamA.wins = 0;
  data.teamH.wins = 0;
  data.teamA.loss = 0;
  data.teamH.loss = 0;
  data.teamA.name = teamA;
  data.teamH.name = teamH;
  data.teamH.rates = teamHStats;
  data.teamA.rates = teamAStats;
  data.eigame = 0;
}

export default function playBall(maxGames, teamA, teamH, teamAStats, teamHStats) {
  if (maxGames > 162) {
    maxGames = 162;
  } else if (maxGames < 1) {
    maxGames = 1;
  }
  resetData(teamA, teamH, teamAStats, teamHStats);
  let count = 0;
  while (count < maxGames) {
    simulateGame();
    count++;
  }
  console.log(data.teamA.name + ': ' + data.teamA.wins + '-' + data.teamA.loss);
  console.log(data.teamH.name + ': ' + data.teamH.wins + '-' + data.teamH.loss);
  console.log('# of extra inning games =>' + data.eigame);

  return data;
}
