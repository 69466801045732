
import { useEffect, useState } from 'react';
import { calculateTeamHitting, calculateTeamPitching } from '../../algorithmn';
import RankingRow from './RankingRow';
import './RankingsPage.css';

let sortBy = require('lodash.sortby');


const RankingsPage = (props) => {
  const [selectedStatBasis, setSelectedStatBasis] = useState('total')
  const [selectedRowCount, setSelectedRowCount] = useState('10')
  const [selectedSortDirection, setSelectedSortDirection] = useState('d')

  let orderedDisplayArray = [];
  let hittingArray = [];
  let pitchingArray = [];
  let totalArray = [];

  if (selectedStatBasis !== 'pitching') {
    props.hittingStats.forEach((team) => {
      let hittingRank = Math.round(calculateTeamHitting(team))
      hittingArray.push({ id: team.teamAbbrev, rank: hittingRank, teamName: team.teamName })
    })
  }
  if (selectedStatBasis !== 'hitting') {
    props.pitchingStats.forEach((team) => {
      let pitchingRank = Math.round(calculateTeamPitching(team))
      pitchingArray.push({ id: team.teamAbbrev, rank: pitchingRank, teamName: team.teamName })
    })
  }
  if (selectedStatBasis === 'total') {
    // combine the two arrays
    let tempSortHitting = sortBy(hittingArray, ['id']);
    let tempSortPitching = sortBy(pitchingArray, ['id']);
    tempSortHitting.forEach((team, idx = 0) => {
      let summation = team.rank + tempSortPitching[idx].rank;
      idx++;
      totalArray.push({ id: team.teamAbbrev, rank: summation, teamName: team.teamName })
    })
  }

  if (selectedStatBasis === 'hitting') {
    orderedDisplayArray = hittingArray;
  } else if (selectedStatBasis === 'pitching') {
    orderedDisplayArray = pitchingArray;
  } else {
    orderedDisplayArray = totalArray;
  }

  // organize ascending by rank
  let sortedDisplayArray = sortBy(orderedDisplayArray, ['rank']);

  // Add in an indicator to the final object of RANK position
  let tempAddPositionArray = [];
  let counter = 30
  sortedDisplayArray.forEach((team) => {
    tempAddPositionArray.push({ ...team, position: counter })
    counter--;
  })
  sortedDisplayArray = tempAddPositionArray


  // if desc selected then reverse it
  if (selectedSortDirection === 'd') {
    sortedDisplayArray = sortedDisplayArray.reverse();
  }

  // console.log(teamHitting.stats[0].teamAbbrev + ':' + (teamHitting.stats[0].homeRuns / teamHitting.stats[0].gamesPlayed) + " home runs per game")

  // truncate length of sorted array for display
  sortedDisplayArray.length = selectedRowCount;


  return (
    <>
      <div>
        <h3><span className='sabr-score-year-title'>{props.statsYearSelected}</span> SABRScore{props.statsYearSelected === '2020' ? ' (short season)' : ''}</h3>
        <div className='sabr-notification-banner'>The beta version of this score is available, it will be fine tuned over the course of the season.
          <br />2024 uses real-time data, whereas the past 10 seasons are also available to compare against with the final season stats.<br />
          {props.statsYearSelected === '2020' ? '*2020 was a COVID shortened season and resulted in a not-full season sample size to compare against!' : ""}</div>
        <br />
        <div className='sabr-score-box'>
          Stat basis:
          <select onChange={(sel) => setSelectedStatBasis(sel.target.value)} value={selectedStatBasis}>
            <option value='total'>Combined</option>
            <option value='hitting'>Hitting</option>
            <option value='pitching'>Pitching</option>
          </select>
          <br />
          <br />
          Year basis:
          <select onChange={(sel) => props.setYearStatsFn(sel.target.value)} value={props.statsYearSelected}>
            <option value='2024'>2024</option>
            <option value='2023'>2023</option>
            <option value='2022'>2022</option>
            <option value='2021'>2021</option>
            <option value='2020'>2020*</option>
            <option value='2019'>2019</option>
            <option value='2018'>2018</option>
            <option value='2017'>2017</option>
            <option value='2016'>2016</option>
            <option value='2015'>2015</option>
            <option value='2014'>2014</option>
          </select>
          <br />
          <br />
          Number of Rows:
          <select onChange={(sel) => setSelectedRowCount(sel.target.value)} value={selectedRowCount}>
            <option value='5'>5</option>
            <option value='10'>10</option>
            <option value='15'>15</option>
            <option value='30'>30</option>
          </select>
          <br />
          <br />
          Sort direction:
          <select onChange={(sel) => setSelectedSortDirection(sel.target.value)} value={selectedSortDirection}>
            <option value='a'>Low-High</option>
            <option value='d'>High-Low</option>
          </select>
        </div>
        <br />
        <div className='sabr-score-box'>
          {sortedDisplayArray.map((rowItem, key) => {
            return <RankingRow rowItem={rowItem} key={key} />
          })}
        </div>
        <br />
        <br />
        <br />
      </div>
    </>
  )
}

export default RankingsPage