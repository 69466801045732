import React, { Component } from 'react';
import Scoreboard from '../Scoreboard/Scoreboard';
import teamData from '../../data/teamData.json';
import statsData from '../../data/teamStats.json';
import baseballGame from '../../helpers/baseball';

import './Simulations.css';


class Simulations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      years: [],
      teamsH: [],
      teamsA: [],
      selectedYearH: '2018', // home team year
      selectedYearA: '2018',
      selectedTeamH: 'Milwaukee Brewers', // selected home team
      selectedTeamA: 'Chicago Cubs',
      selectedTeamHabr: 'MIL',
      selectedTeamAabr: 'CHC',
      winsA: null,
      winsH: null,
      resultArray: [],
      maxGames: 1,
      showTable: false,
    };

    this.inputValidation = this.inputValidation.bind(this);
    this.blurValidation = this.blurValidation.bind(this);
    this.selectTeamYear = this.selectTeamYear.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    let keys = Object.keys(teamData);
    keys = keys.reverse();
    this.setState({
      years: keys,
    });
    const hTeamKeys = Object.keys(teamData[keys[0]]);
    // console.log(hTeamKeys);
    const hTeamAbr = teamData[2018][hTeamKeys[15]];
    // console.log(hTeamAbr);
    this.setState({
      teamsH: hTeamKeys,
      selectedTeamH: hTeamKeys[15],
      selectedTeamHabr: hTeamAbr,
    });
    const aTeamKeys = Object.keys(teamData[keys[0]]);
    const aTeamAbr = teamData[2018][aTeamKeys[4]];
    this.setState({
      teamsA: aTeamKeys,
      selectedTeamA: aTeamKeys[4],
      selectedTeamAabr: aTeamAbr,
    });
  }


  onClick(manual, maxGames) {
    this.setState({
      resultArray: [],
    });
    let numGames = this.state.maxGames;
    if (manual) {
      numGames = maxGames;
    }


    const aStats = statsData[this.state.selectedYearA][this.state.selectedTeamAabr];
    const hStats = statsData[this.state.selectedYearH][this.state.selectedTeamHabr];

    // build the objects for the rates to pass
    if (aStats.SNR < 1000) {
      aStats.BA *= 1000; // batting average
      aStats.HRR *= 1000; // homerun rate
      aStats.TRR = (aStats.TRR * 1000) + aStats.HRR; // triple rate
      aStats.DBR = (aStats.DBR * 1000) + aStats.TRR; // double rate
      aStats.SNR = (aStats.SNR * 1000) + aStats.DBR; // single rate
    }

    if (hStats.SNR < 1000) {
      hStats.BA *= 1000; // batting average
      hStats.HRR *= 1000; // homerun rate
      hStats.TRR = (hStats.TRR * 1000) + hStats.HRR; // triple rate
      hStats.DBR = (hStats.DBR * 1000) + hStats.TRR; // double rate
      hStats.SNR = (hStats.SNR * 1000) + hStats.DBR; // single rate
    }

    const data = baseballGame(numGames, this.state.selectedTeamAabr, this.state.selectedTeamHabr, aStats, hStats);
    const result = data.result; // eslint-disable-line

    this.setState({
      resultData: data,
      resultArray: result,
      showTable: true,
      winsA: data.teamA.wins,
      winsH: data.teamH.wins,
    });
  }

  selectTeamYear(event) {
    if (event.target.id === 'selectedTeamH' || event.target.id === 'selectedTeamA') {
      const abr = `${event.target.id}abr`;
      const abrValue = teamData[2018][event.target.value];
      this.setState({
        [event.target.id]: event.target.value,
        [abr]: abrValue,
        showTable: false,
      });
    } else {
      this.setState({
        [event.target.id]: event.target.value,
        showTable: false,
      });
    }
  }

  inputValidation(e) {
    if (e.keyCode === 13 || e.key === 'Enter') {
      this.setState({
        maxGames: e.target.value,
      }, this.onClick(true, e.target.value));
    }
  }

  blurValidation(e) {
    const pattern = new RegExp(/^\d*$/);
    if (e.target.value > 162) {
      e.target.value = 162;
    } else if (e.target.value < 1) {
      e.target.value = 1;
    } else if (!pattern.test(e.target.value)) {
      e.target.value = 1;
    }
    this.setState({
      maxGames: e.target.value,
    });
  }

  render() {
    const yrs = this.state.years;
    const optionYears = yrs.map(yrs => <option key={yrs} value={yrs}>{yrs}</option>);

    const tma = this.state.teamsA;
    const optionAway = tma.map(tma => <option key={tma} value={tma}>{tma}</option>);

    const tmh = this.state.teamsH;
    const optionHome = tmh.map(tmh => <option key={tmh} value={tmh}>{tmh}</option>);

    const rst = this.state.resultArray;
    const results = rst.map((result, i) => {
      return (
        <tr key={i}><td className='resultsList gameFontSize'>Game #{i + 1}</td>
          <td><Scoreboard data={this.state.resultData} results={this.state.resultArray} id={i} /></td>
          <td className='resultsList gameFontSize'></td></tr>
      );
    })

    // console.log(this.state)

    return (
      <div className='body-cont'>

        <hr />
        <div className='simulate-cont'>

          {/* START OF LEFT COLUMN */}
          <div className='simulate-cont-left'>
            <span className='input-group'>
              <select className='yearSelect' id='selectedYearA' onChange={this.selectTeamYear} defaultValue={this.state.selectedYearA}>
                {optionYears}
              </select>
              <select className='teamSelect' id='selectedTeamA' onChange={this.selectTeamYear} value={this.state.selectedTeamA}>
                {optionAway}
              </select>
            </span>
            <span className='atsymb'>at</span>
            <span className='input-group'>
              <select className='yearSelect' id='selectedYearH' onChange={this.selectTeamYear} defaultValue={this.state.selectedYearH}>
                {optionYears}
              </select>
              <select className='teamSelect' id='selectedTeamH' onChange={this.selectTeamYear} value={this.state.selectedTeamH}>
                {optionHome}
              </select>
            </span>

            <span className='input-group'>
              <span className='inputPos'>
                <input className='numGames' type='text' defaultValue='1' onBlur={this.blurValidation} onKeyDown={this.inputValidation} />
                <span className='numGamesTxt'>game(s) in series</span>
              </span>
            </span>
            <span className='input-group'>
              <span className='submitPos'>
                <button type='button' className='btn simButton' onClick={() => this.onClick(false, 0)}>Play Ball</button>
              </span>
            </span>
          </div>

          {/* RIGHT COLUMN START */}
          <div className='simulate-cont-right'>
            {this.state.showTable && (this.state.winsA || this.state.winsH)
              ? (<div className="results-text">
                {this.state.winsA > this.state.winsH
                  ? (<div className='resultMessage'>The {this.state.selectedTeamA} won the series over the {this.state.selectedTeamH} {this.state.winsA}-{this.state.winsH}.</div>)
                  : this.state.winsA !== this.state.winsH
                    ? (<div className='resultMessage'>The {this.state.selectedTeamH} won the series over the {this.state.selectedTeamA} {this.state.winsH}-{this.state.winsA}.</div>)
                    : (<div className='resultMessage'>The {this.state.selectedTeamA} tied the series with {this.state.selectedTeamH} {this.state.winsH}-{this.state.winsA}.</div>)
                }
              </div>)
              : null}

            <div className='resultsList-div'>
              <div className='resultsList-title'>
                <h5>{this.state.selectedYearA} {this.state.selectedTeamA}</h5>
                <h5>&nbsp;@&nbsp;</h5>
                <h5>{this.state.selectedYearH} {this.state.selectedTeamH}</h5>
              </div>
              <div className='resultsListScroll'>
                <table className='resultsList table-centered'>
                  <thead>

                  </thead>
                  <tbody>
                    {results}
                  </tbody>
                </table>
              </div>

              {this.state.resultArray.length > 4 && <div>
                <br />
                <a href='#sb-header'>Back to Top</a>
                <br />
              </div>}

            </div>

          </div> {/* end of the right container */}
        </div>

        <br /><br /><br />
      </div>

    );
  }
}

export default Simulations;
