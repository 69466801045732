import './Footer.css';

const Footer = (props) => {
  return (
    <>
      <div id='sb-footer' className='bg-darkblue height-banner flex'>
        <h4 id='sb-footer-text' className='bg-darkblue font-white'>&copy;2024 SimulateBaseball.com</h4>
      </div>
    </>
  )
}

export default Footer;