

const RankingRow = (props) => {

  return (
    <div className='sabr-score-row'>
      <div className='sabr-score-text'><span className='sabr-score-pos'>{props.rowItem.position}</span> {props.rowItem.teamName}: <span className='sabr-score-bold'>{props.rowItem.rank}</span></div>
    </div>
  )
}

export default RankingRow;