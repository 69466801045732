export const calculateTeamHitting = (team) => {
  let score = 0;

  // hitting +
  score += hittingPlus(team);

  // hitting -
  score += hittingNegative(team);

  return score;
}

export const calculateTeamPitching = (team) => {
  let score = 0;

  // pitching +
  // score += team.pitchesPerInning


  // pitching -

  return score;
}

// Positive Hitting numbers, these things will be positives that help boost the score up
const hittingPlus = (team) => {
  let score = 0;

  // home runs per game
  score += ((team.homeRuns / team.gamesPlayed) * 15);

  // batting average rankings
  if (team.avg >= .285) {
    score += 30
  } else if (team.avg >= .275) {
    score += 25
  } else if (team.avg >= .265) {
    score += 20
  } else if (team.avg >= .260) {
    score += 18
  } else if (team.avg >= .250) {
    score += 16
  } else if (team.avg >= .240) {
    score += 14
  } else if (team.avg >= .230) {
    score += 12
  } else {
    score += 6
  }

  // OBP rankings
  if (team.ops >= .850) {
    score += 25
  } else if (team.ops >= .800) {
    score += 20
  } else if (team.ops >= .750) {
    score += 15
  } else if (team.ops >= .700) {
    score += 10
  } else if ((team.ops >= .699)) {
    score += 5
  }

  // stolen base %
  if (team.stolenBasePercentage >= .900) {
    score += 25
  } else if (team.stolenBasePercentage >= 0.850) {
    score += 20
  } else if (team.stolenBasePercentage >= 0.800) {
    score += 15
  } else if (team.stolenBasePercentage >= 0.750) {
    score += 10
  } else if (team.stolenBasePercentage >= 0.700) {
    score += 5
  }

  // add in the total stolen bases
  score += team.stolenBases

  // walks per game
  if (team.baseOnBalls / team.gamesPlayed >= 6) {
    score += 25
  } else if (team.baseOnBalls / team.gamesPlayed >= 5) {
    score += 20
  } else if (team.baseOnBalls / team.gamesPlayed >= 4) {
    score += 15
  } else if (team.baseOnBalls / team.gamesPlayed >= 3) {
    score += 10
  } else if (team.baseOnBalls / team.gamesPlayed >= 2) {
    score += 5
  } else if (team.baseOnBalls / team.gamesPlayed >= 1) {
    score += 2
  } else {
    score += 1
  }

  // walks + ibb per plate appearance (range is 0.7 to 0.12)
  let wibbppa = (((team.baseOnBalls + team.intentionalWalks) / team.plateAppearances)).toFixed(3);
  if (wibbppa >= 0.12) {
    score += 30
  } else if (wibbppa >= 0.11) {
    score += 25
  } else if (wibbppa >= 0.10) {
    score += 20
  } else if (wibbppa >= 0.90) {
    score += 15
  } else if (wibbppa >= 0.80) {
    score += 10
  } else if (wibbppa >= 0.70) {
    score += 5
  }

  // number of pitches per at bat
  score += (team.numberOfPitches / team.atBats);

  return score;
}

// Negative Hitting numbers, these things will be negatives that detract from the score
const hittingNegative = (team) => {
  let score = 0;

  // grounded into double play per game
  let gidppg = team.groundIntoDoublePlay / team.gamesPlayed;
  if (gidppg >= 0.9) {
    score -= 30
  } else if (gidppg >= 0.85) {
    score -= 27
  } else if (gidppg >= 0.80) {
    score -= 24
  } else if (gidppg >= 0.75) {
    score -= 21
  } else if (gidppg >= 0.70) {
    score -= 18
  } else if (gidppg >= 0.65) {
    score -= 15
  } else if (gidppg >= 0.60) {
    score -= 12
  } else if (gidppg >= 0.55) {
    score -= 9
  } else if (gidppg >= 0.50) {
    score -= 6
  } else if (gidppg >= 0.45) {
    score -= 3
  }

  // left on base per game
  score -= (team.leftOnBase / team.gamesPlayed);

  //at bats per home run
  // score -= team.atBatsPerHomeRun;

  score -= (team.strikeOuts / team.gamesPlayed);

  return score;
}

