import React from 'react';

const UnderConstruction = (props) => {

  return (
    <>
      <div id='under-construction-banner'>
        <h4>2022 Regular Season</h4>
        <p>Coming soon :D it's been a very busy time!</p>
      </div>
    </>
  )
}

export default UnderConstruction;